<template>
  <div>
    <DataTable
      :loading="loading"
      :headers="headers"
      :can-search="true"
      :infinite-scroll="true"
      v-bind="communications"
      class="tw--m-5 tw-shadow-none"
      @filter="loadCommunications"
      @infiniteLoad="infiniteLoad"
      @changedOrder="loadCommunications"
    >
      <template #toolbar>
        <div class="tw-mb-4 tw-flex tw-justify-end tw-gap-4">
          <button
            type="button"
            class="action tw-mr-0 tw-bg-success"
            @click="showCommunicationModal({ type: 'owner' })"
          >
            <i class="fa fa-plus tw-mr-1" /> Communicatie met eigenaar
          </button>
          <button
            v-if="showRenterCommunicationButton"
            type="button"
            class="action tw-mr-0 tw-bg-success"
            @click="showCommunicationModal({ type: 'renter' })"
          >
            <i class="fa fa-plus tw-mr-1" /> Communicatie met huurder
          </button>
          <button
            type="button"
            class="action tw-mr-0 tw-bg-success"
            @click="showCommunicationModal({ type: 'default' })"
          >
            <i class="fa fa-plus tw-mr-1" /> Communicatie
          </button>
        </div>
      </template>

      <template #filters="{ filterBy }">
        <select
          title="Type"
          placeholder="Type"
          class="tw-border-b tw-bg-white"
          @input="filterBy(['type_id'], $event.target.value)"
        >
          <option value="" selected>Geen type</option>
          <option disabled>---------</option>
          <option
            v-for="type in communicationTypes"
            :key="type.id"
            :value="type.id"
            :title="type.name_reporting_nl"
          >
            {{ type.name_reporting_nl }}
          </option>
        </select>
      </template>
      <template #item-relation="{ item }">
        <router-link
          v-if="item.relation"
          :to="
            item.match
            ? { name: 'LeadDetails', params: { id: item.match } }
            : { name: 'ContactDetails', params: { id: item.relation.id } }
          "
        >
          {{ item.relation.display_name }}
        </router-link>
        <span v-else>-</span>
      </template>

      <template #item-internal="{ item }">
        <div v-html="item.internal" />
      </template>

      <template #item-external="{ item }">
        <div v-html="item.external" />
      </template>

      <template #item-timestamp="{ item }">
        <span v-text="formatDateTime(item.timestamp)" />
      </template>

      <template #item-actions="{ item }">
        <div class="tw-flex">
          <button
            title="Communicatie bewerken"
            type="button"
            class="action tw-bg-gray-500"
            @click="showCommunicationModal({ values: item })"
          >
            <i class="fas fa-pencil"/>
          </button>
          <button
            title="Communicatie verwijderen"
            type="button"
            class="action tw-bg-danger"
            @click="removeCommunication(item.id)"
          >
            <i class="fas fa-trash-alt"/>
          </button>
          <button
            v-if="!item.match && item.relation"
            type="button"
            title="Lead aanmaken"
            class="action tw-bg-success"
            @click="newLead(item)"
          >
            <i class="fas fa-user-plus"/>
          </button>
        </div>
      </template>
    </DataTable>

    <CommunicationModal
      ref="communicationModal"
      v-bind="entityProps"
      @success="loadCommunications"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { formatDateTime } from '@/utils/helpers'

import DataTable from '@/components/DataTable'
import CommunicationModal from '@/components/iam/CommunicationModal'

import { mapActions } from 'vuex'
import {
  errorModal,
  questionModal,
  startLoadingModal,
  successModal
} from '@/modalMessages'

import {
  getPropertyOwners,
  getPropertyRenters,
  deletePropertyCommunication,
  createPropertyCommunication,
  getPropertyCommunications
} from '@/services/properties'

import {
  getProjectOwners,
  deleteProjectCommunication,
  createProjectCommunication,
  getProjectCommunications
} from '@/services/projects'

import { createActivity } from '@/services/sales'
import { getCommunicationTypes } from '@/services/apiService'

export default {
  name: 'CommunicationPanel',
  components: {
    DataTable,
    CommunicationModal
  },
  props: {
    project: {
      type: Object,
      default: null
    },
    property: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      // The object communications includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      communications: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      communicationWithType: 'default',
      communicationTypes: [],
      renters: [],
      owners: []
    }
  },
  computed: {
    headers () {
      return [
        { text: 'Contact', value: 'relation', orderable: true, orderByKey: 'relation_name' },
        { text: 'Type', value: 'type.name_reporting_nl', orderable: true, orderByKey: 'type__name_reporting_nl' },
        { text: 'Datum', value: 'timestamp', orderable: true },
        { text: 'Intern', value: 'internal' },
        { text: 'Extern', value: 'external' },
        { text: 'Actie', value: 'actions' }
      ]
    },
    showRenterCommunicationButton () {
      return this.property && this.renters && this.property.transaction_type === 3 // 3 = rental transaction type
    },
    entityProps () {
      const props = {}
      if (this.project) props.projectId = this.project.id
      if (this.property) props.propertyId = this.property.id
      return props
    }
  },
  created () {
    this.init()
  },
  methods: {
    formatDateTime,
    ...mapActions('sales', [
      'createLead',
      'checkExistingLead'
    ]),

    showCommunicationModal ({ values, type }) {
      let relation = null
      if (type === 'owner' && this.owners?.length) relation = this.owners[0]
      if (type === 'renter' && this.renters?.length) relation = this.renters[0]
      this.$refs.communicationModal.show(values, relation)
    },

    async init () {
      try {
        const response = Promise.all([
          this.loadCommunicationTypes(),
          this.loadCommunications(),
          this.loadRenters(),
          this.loadOwners()
        ])
        return response
      } catch (error) {
        errorModal('Fout bij het laden van communicatiegegevens, probeer het opnieuw.')
      }
    },

    async getCommunications (payload) {
      const response = this.property?.id
        ? await getPropertyCommunications({ propertyId: this.property.id, ...payload })
        : await getProjectCommunications({ projectId: this.project.id, ...payload })
      return response.data
    },
    async loadCommunications (payload) {
      try {
        this.loading = true
        const communications = await this.getCommunications(payload)
        this.communications = communications
        return communications
      } catch (error) {
        console.error(error)
        errorModal('Fout bij het laden van communicatie, probeer het opnieuw.')
      } finally {
        this.loading = false
      }
    },
    async infiniteLoad ($infinite, next) {
      try {
        if (!next) {
          // No more data and state is loaded
          if (this.communications.results.length) $infinite.loaded()
          return $infinite.complete()
        }
        const communications = await this.getCommunications({ url: next })
        const results = [...this.communications.results, ...communications.results]
        this.communications = { ...communications, results }
        $infinite.loaded()
        return communications
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },

    async loadCommunicationTypes () {
      try {
        const response = await getCommunicationTypes()
        this.communicationTypes = response.data.results
        return response
      } catch (error) {
        errorModal('Fout bij het laden van communicatietypes, probeer het opnieuw.')
      }
    },

    async loadOwners () {
      try {
        let response
        if (this.property) {
          response = await getPropertyOwners(this.property.id)
        } else if (this.project) {
          response = await getProjectOwners(this.project.id)
        }
        this.owners = response.data
        return response
      } catch (error) {
        errorModal('Fout bij het laden van eigenaren, probeer het opnieuw.')
      }
    },

    async loadRenters () {
      try {
        if (!this.property) return
        const response = await getPropertyRenters(this.property.id)
        this.renters = response.data
        return response
      } catch (error) {
        errorModal('Fout bij het laden van huurders, probeer het opnieuw.')
      }
    },

    async saveCommunication (data) {
      try {
        startLoadingModal('Communicatie opslaan...')

        const payload = { ...data }
        if (this.communicationWithType === 'owner' && this.owners?.length) payload.relation = this.owners[0].id
        if (this.communicationWithType === 'renter' && this.renters?.length) payload.relation = this.renters[0].id

        let response
        if (this.property) response = await createPropertyCommunication(this.property.id, payload)
        if (this.project) response = await createProjectCommunication(this.project.id, payload)

        this.$refs.communicationModal.hide()
        await this.loadCommunications()
        successModal('Communicatie succesvol aangemaakt.')
        return response
      } catch (error) {
        if (this.project && error.status === 404) errorModal('Er ging iets mis bij het aanmaken van de communicatie, gelieve opnieuw te proberen')
        else errorModal('Project heeft geen panden om communicatie voor te maken, gelieve er een aan te maken')
      }
    },

    async removeCommunication (communicationId) {
      try {
        const result = await questionModal('Deze actie kan niet ongedaan gemaakt worden')
        if (!result.value) return

        startLoadingModal('Communicatie verwijderen...')

        let response
        if (this.property) response = await deletePropertyCommunication(this.property.id, communicationId)
        if (this.project) response = await deleteProjectCommunication(this.project.id, communicationId)

        await this.loadCommunications()
        successModal('Communicatie is verwijderd')
        return response
      } catch (error) {
        errorModal('Fout bij verwijderen van communicatie, probeer het opnieuw.')
      }
    },

    async newLead (communication) {
      try {
        const contact_id = communication?.relation?.id
        const property_id = communication?.property_id

        if (!contact_id) return errorModal('Deze communicatie heeft geen contact, kan geen lead creëren.')

        const result = await questionModal('Wil je een lead aanmaken voor deze communicatie?')
        if (!result.value) return

        const existingLead = await this.checkExistingLead({ contact_id, property_id })
        if (existingLead) return this.$router.push({ name: 'LeadDetails', params: { id: existingLead.id } })

        const lead = await this.createLead({ candidates: [contact_id], property: property_id })

        const createActivityPayload = {
          type: communication?.type?.id,
          name: communication?.type?.name_reporting_nl,
          description: communication?.internal,
          communication_id: communication?.id,
          start_date: moment().format(),
          end_date: moment().format()
        }
        await createActivity(lead.id, createActivityPayload)
        this.$router.push({ name: 'LeadDetails', params: { id: lead.id } })
        return lead
      } catch (error) {
        const errorMessages = error.responseJSON
        if (errorMessages && errorMessages.length >= 1) {
          const errorMessage = errorMessages[0]
          errorModal(`Er ging iets mis bij het aanmaken van de lead: ${errorMessage}`, true)
        }
        console.error(error)
      }
    }
  }
}
</script>
