<template>
  <BaseModal ref="modal" :title="title">
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="newCommunicationForm"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="submit"
    >
      <template v-if="!edit">
        <p>
          Via dit scherm kan je manueel communicatie registreren voor dit dossier.
          Het veld <strong>{{ contactId ? 'Pand' : 'Contact' }}</strong> is <em>optioneel</em> en kan je dus leeglaten.
        </p>
        <p>
          Let wel: communicatie die op deze manier geregistreerd wordt komt <strong>niet op de eigenaarsrapportering</strong>.
          Enkel communicatie die geregistreerd wordt door het afpunten van een activiteit op een lead zal zichtbaar zijn in de eigenaarsrapportering.
        </p>

        <FormulateInput
          v-if="contactId"
          id="property"
          type="autocomplete"
          auto-complete-type="property"
          name="property"
          label="Pand"
          placeholder="Selecteer pand"
        />
        <FormulateInput
          v-else
          id="relation"
          type="autocomplete"
          auto-complete-type="contact"
          name="relation"
          label="Contact"
          placeholder="Selecteer contact"
        >
          <template #label="{ label, id, classes }">
            <div class="tw-flex tw-justify-between">
              <label
                :for="id"
                v-text="label"
                :class="classes.label"
                class="formulate-label"
              />
              <button
                type="button"
                class="link tw-font-semibold"
                @click="showContactCreateModal"
              >
                <i class="far fa-user-plus" /> Maak nieuw
              </button>
            </div>
          </template>
        </FormulateInput>
      </template>

      <div class="tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4">
        <FormulateInput
          type="datetime-local"
          name="timestamp"
          label="Tijdstip"
          placeholder="YYYY-MM-DD"
          validation="bail|required|date"
          outer-class="tw-my-0"
        />
        <FormulateInput
          type="select"
          name="type"
          label="Type communicatie"
          placeholder="Selecteer type"
          :options="communicationTypes"
          value="1"
          validation="required"
          outer-class="tw-my-0"
        />
      </div>
      <FormulateInput
        type="textarea"
        name="internal"
        label="Interne communicatie"
        placeholder="Interne communicatie"
        :input-class="['tw-h-24']"
      />
      <FormulateInput
        type="textarea"
        name="external"
        label="Externe communicatie"
        placeholder="Externe communicatie"
        :input-class="['tw-h-24']"
      />

      <FormulateErrors class="tw-text-right" />

      <div class="tw-flex tw-justify-end">
        <FormulateInput
          type="submit"
          :disabled="isLoading"
          outer-class="tw-mt-4"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          Opslaan
        </FormulateInput>
      </div>
    </FormulateForm>

    <ContactCreateModal
      ref="contactCreateModal"
      :redirect="false"
      @success="newOwnerCreated"
    />
  </BaseModal>
</template>

<script>
import { format } from 'date-fns'
import { successModal } from '@/modalMessages'

import ContactCreateModal from '@/components/contacts/ContactCreateModal'

import { getCommunicationTypes } from '@/services/apiService'
import { createContactCommunication, updateContactCommunication } from '@/services/contacts'
import { createProjectCommunication, updateProjectCommunication } from '@/services/projects'
import { createPropertyCommunication, updatePropertyCommunication } from '@/services/properties'

export default {
  name: 'CommunicationModal',
  props: {
    contactId: {
      type: [Number, String]
    },
    propertyId: {
      type: [Number, String]
    },
    projectId: {
      type: [Number, String]
    }
  },
  components: {
    ContactCreateModal
  },
  data () {
    return {
      edit: false,
      values: {
        // default
        timestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm")
      },
      communicationTypes: []
    }
  },
  computed: {
    title () {
      return this.edit ? 'Communicatie bewerken' : 'Nieuwe communicatie registreren'
    }
  },
  created () {
    this.loadCommunicationTypes()
  },
  methods: {
    showContactCreateModal () {
      this.$refs.contactCreateModal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    show (communication, relation) {
      if (communication) {
        this.edit = true
        const { type, ...values } = communication
        this.values = { type: type.id, ...values }
      } else {
        // Set defaults
        this.edit = false
        this.values = {
          timestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm")
        }
      }
      if (relation) this.$set(this.values, 'relation', relation)
      this.$refs.modal.show()
    },
    newOwnerCreated (contact) {
      const { type, company_name, first_name, last_name } = contact
      const display_name = type === 'B' ? company_name : `${first_name} ${last_name}`
      this.$set(this.values, 'relation', { display_name, ...contact })
      return contact
    },

    async loadCommunicationTypes () {
      const response = await getCommunicationTypes()
      const types = response.data.results || []
      this.communicationTypes = types.map(type => {
        return { value: type.id, label: type.name_reporting_nl }
      })
      return response
    },
    async submit (data) {
      try {
        const { property, relation, ...payload } = data
        if (property) payload.property = property.id
        if (relation) payload.relation = relation.id

        const response = this.edit
          ? await this.updateCommunication(payload)
          : await this.createCommunication(payload)
        this.$emit('success')
        this.hide()
        return response
      } catch (error) {
        this.$formulate.handle(error, 'newCommunicationForm')
      }
    },
    async createCommunication (payload) {
      const response = this.contactId
        ? await createContactCommunication(this.contactId, payload)
        : this.propertyId
          ? await createPropertyCommunication(this.propertyId, payload)
          : await createProjectCommunication(this.projectId, payload)
      successModal('Communicatie succesvol gemaakt.')
      return response
    },
    async updateCommunication (payload) {
      const { id, ...values } = payload
      const response = this.contactId
        ? await updateContactCommunication(this.contactId, id, values)
        : this.propertyId
          ? await updatePropertyCommunication(this.propertyId, id, values)
          : await updateProjectCommunication(this.projectId, id, values)
      successModal('Communicatie is successvol aangepast')
      return response
    }
  }
}
</script>
