var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataTable',_vm._b({staticClass:"tw--m-5 tw-shadow-none",attrs:{"loading":_vm.loading,"headers":_vm.headers,"can-search":true,"infinite-scroll":true},on:{"filter":_vm.loadCommunications,"infiniteLoad":_vm.infiniteLoad,"changedOrder":_vm.loadCommunications},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('div',{staticClass:"tw-mb-4 tw-flex tw-justify-end tw-gap-4"},[_c('button',{staticClass:"action tw-mr-0 tw-bg-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.showCommunicationModal({ type: 'owner' })}}},[_c('i',{staticClass:"fa fa-plus tw-mr-1"}),_vm._v(" Communicatie met eigenaar ")]),(_vm.showRenterCommunicationButton)?_c('button',{staticClass:"action tw-mr-0 tw-bg-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.showCommunicationModal({ type: 'renter' })}}},[_c('i',{staticClass:"fa fa-plus tw-mr-1"}),_vm._v(" Communicatie met huurder ")]):_vm._e(),_c('button',{staticClass:"action tw-mr-0 tw-bg-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.showCommunicationModal({ type: 'default' })}}},[_c('i',{staticClass:"fa fa-plus tw-mr-1"}),_vm._v(" Communicatie ")])])]},proxy:true},{key:"filters",fn:function(ref){
var filterBy = ref.filterBy;
return [_c('select',{staticClass:"tw-border-b tw-bg-white",attrs:{"title":"Type","placeholder":"Type"},on:{"input":function($event){return filterBy(['type_id'], $event.target.value)}}},[_c('option',{attrs:{"value":"","selected":""}},[_vm._v("Geen type")]),_c('option',{attrs:{"disabled":""}},[_vm._v("---------")]),_vm._l((_vm.communicationTypes),function(type){return _c('option',{key:type.id,attrs:{"title":type.name_reporting_nl},domProps:{"value":type.id}},[_vm._v(" "+_vm._s(type.name_reporting_nl)+" ")])})],2)]}},{key:"item-relation",fn:function(ref){
var item = ref.item;
return [(item.relation)?_c('router-link',{attrs:{"to":item.match
          ? { name: 'LeadDetails', params: { id: item.match } }
          : { name: 'ContactDetails', params: { id: item.relation.id } }}},[_vm._v(" "+_vm._s(item.relation.display_name)+" ")]):_c('span',[_vm._v("-")])]}},{key:"item-internal",fn:function(ref){
          var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.internal)}})]}},{key:"item-external",fn:function(ref){
          var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.external)}})]}},{key:"item-timestamp",fn:function(ref){
          var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.formatDateTime(item.timestamp))}})]}},{key:"item-actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"tw-flex"},[_c('button',{staticClass:"action tw-bg-gray-500",attrs:{"title":"Communicatie bewerken","type":"button"},on:{"click":function($event){return _vm.showCommunicationModal({ values: item })}}},[_c('i',{staticClass:"fas fa-pencil"})]),_c('button',{staticClass:"action tw-bg-danger",attrs:{"title":"Communicatie verwijderen","type":"button"},on:{"click":function($event){return _vm.removeCommunication(item.id)}}},[_c('i',{staticClass:"fas fa-trash-alt"})]),(!item.match && item.relation)?_c('button',{staticClass:"action tw-bg-success",attrs:{"type":"button","title":"Lead aanmaken"},on:{"click":function($event){return _vm.newLead(item)}}},[_c('i',{staticClass:"fas fa-user-plus"})]):_vm._e()])]}}])},'DataTable',_vm.communications,false)),_c('CommunicationModal',_vm._b({ref:"communicationModal",on:{"success":_vm.loadCommunications}},'CommunicationModal',_vm.entityProps,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }