var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{ref:"modal",attrs:{"title":_vm.title}},[_c('FormulateForm',{attrs:{"name":"newCommunicationForm","invalid-message":"Gelieve de verplichte velden correct in te vullen."},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [(!_vm.edit)?[_c('p',[_vm._v(" Via dit scherm kan je manueel communicatie registreren voor dit dossier. Het veld "),_c('strong',[_vm._v(_vm._s(_vm.contactId ? 'Pand' : 'Contact'))]),_vm._v(" is "),_c('em',[_vm._v("optioneel")]),_vm._v(" en kan je dus leeglaten. ")]),_c('p',[_vm._v(" Let wel: communicatie die op deze manier geregistreerd wordt komt "),_c('strong',[_vm._v("niet op de eigenaarsrapportering")]),_vm._v(". Enkel communicatie die geregistreerd wordt door het afpunten van een activiteit op een lead zal zichtbaar zijn in de eigenaarsrapportering. ")]),(_vm.contactId)?_c('FormulateInput',{attrs:{"id":"property","type":"autocomplete","auto-complete-type":"property","name":"property","label":"Pand","placeholder":"Selecteer pand"}}):_c('FormulateInput',{attrs:{"id":"relation","type":"autocomplete","auto-complete-type":"contact","name":"relation","label":"Contact","placeholder":"Selecteer contact"},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var label = ref.label;
var id = ref.id;
var classes = ref.classes;
return [_c('div',{staticClass:"tw-flex tw-justify-between"},[_c('label',{staticClass:"formulate-label",class:classes.label,attrs:{"for":id},domProps:{"textContent":_vm._s(label)}}),_c('button',{staticClass:"link tw-font-semibold",attrs:{"type":"button"},on:{"click":_vm.showContactCreateModal}},[_c('i',{staticClass:"far fa-user-plus"}),_vm._v(" Maak nieuw ")])])]}}],null,true)})]:_vm._e(),_c('div',{staticClass:"tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4"},[_c('FormulateInput',{attrs:{"type":"datetime-local","name":"timestamp","label":"Tijdstip","placeholder":"YYYY-MM-DD","validation":"bail|required|date","outer-class":"tw-my-0"}}),_c('FormulateInput',{attrs:{"type":"select","name":"type","label":"Type communicatie","placeholder":"Selecteer type","options":_vm.communicationTypes,"value":"1","validation":"required","outer-class":"tw-my-0"}})],1),_c('FormulateInput',{attrs:{"type":"textarea","name":"internal","label":"Interne communicatie","placeholder":"Interne communicatie","input-class":['tw-h-24']}}),_c('FormulateInput',{attrs:{"type":"textarea","name":"external","label":"Externe communicatie","placeholder":"Externe communicatie","input-class":['tw-h-24']}}),_c('FormulateErrors',{staticClass:"tw-text-right"}),_c('div',{staticClass:"tw-flex tw-justify-end"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"outer-class":"tw-mt-4"}},[_c('i',{class:[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]}),_vm._v(" Opslaan ")])],1)]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}}),_c('ContactCreateModal',{ref:"contactCreateModal",attrs:{"redirect":false},on:{"success":_vm.newOwnerCreated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }