<template>
  <Tabs
    v-if="entity && Object.keys(entity).length"
    :tabs="TABS"
    :selected-tab="selectedTab"
  >
    <CommunicationPanel slot="communication" v-bind="entityProps" />
    <OwnerReports slot="owner-reports" v-bind="entityProps" />
  </Tabs>
</template>

<script>
import { mapGetters } from 'vuex'
import Tabs from '@/components/Tabs.vue'

import OwnerReports from '@/components/properties/OwnerReports'
import CommunicationPanel from '@/components/properties/CommunicationPanel'

export default {
  name: 'EntityCommunication',
  components: {
    Tabs,
    OwnerReports,
    CommunicationPanel
  },
  constants: {
    TABS: [
      { name: 'Communicatie', target: 'communication' },
      { name: 'Eigenaarsrapportering', target: 'owner-reports' }
    ]
  },
  computed: {
    ...mapGetters('properties', [
      'getProjectById',
      'getPropertyById'
    ]),

    entity () {
      const { entity_type } = this.$route.meta
      const entity = entity_type === 'project'
        ? this.getProjectById(this.$route.params.id)
        : this.getPropertyById(this.$route.params.id)
      return entity
    },
    entityProps () {
      const { entity_type } = this.$route.meta
      return { [entity_type]: this.entity }
    },
    selectedTab () {
      return this.$route.query.tab || ''
    }
  }
}
</script>
