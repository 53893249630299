<template>
  <DataTable
    :loading="loading"
    :headers="headers"
    :infinite-scroll="true"
    v-bind="ownerReports"
    class="tw--mx-5 tw-shadow-none"
    @filter="loadOwnerReports"
    @infiniteLoad="infiniteLoad"
    @changedOrder="loadOwnerReports"
  >
    <template #toolbar>
      <div class="tw-mb-4 tw-flex tw-justify-between tw-gap-4 tw-flex-wrap">
        <router-link :to="ownerReportFormUrl" class="action tw-bg-success">
          <i class="fa fa-plus" /> Eigenaarsrapport
        </router-link>

        <div class="tw-flex tw-flex-wrap tw-gap-4 tw-justify-end tw-items-center">
          <FormulateInput
            v-model="exportLanguage"
            type="select"
            name="language"
            placeholder="Selecteer taal"
            :options="USER_LANG_OPTIONS"
            :input-class="['tw-text-sm tw-h-7 tw-my-0']"
            outer-class="tw-my-0"
          />
          <button
            type="button"
            :disabled="exporting"
            class="action tw-bg-success tw-mr-0"
            @click="exportCandidates"
          >
            <i
              :class="[
                'fas tw-mr-1',
                exporting
                  ? 'fa-spinner-third fa-spin'
                  : 'fa-download'
              ]"
            />
            {{ exporting ? 'Exporteren ...' : 'CSV-export kandidatencontact' }}
          </button>
        </div>
      </div>
    </template>

    <template #item-owner="{ item }">
      <router-link
        v-if="item.owner"
        :to="{ name: 'ContactDetails', params: { id: item.owner.id } }"
      >
        {{ item.owner.display_name }}
      </router-link>
      <span v-else>-</span>
    </template>
    <template #item-created_on="{ item }">
      <span v-if="item.created_on">{{ item.created_on | datetime }}</span>
      <span v-else>-</span>
    </template>
    <template #item-type="{ item }">
      <div class="tw-flex tw-gap-x-2">
        <span>
          {{ item.type === 'period' ? 'Tussentijds rapport' : 'Eindrapport' }}
        </span>
      </div>
    </template>
    <template #item-status="{ item }">
      <div class="tw-flex tw-gap-x-2">
        <span class="tw-bg-success tw-text-white tw-p-1 tw-text-xs tw-font-bold tw-rounded-md">
          {{
            item.delivery_type === 'email'
              ? 'Verzonden via e-mail'
              : 'Bezorgd via post of in persoon'
          }}
        </span>
      </div>
    </template>
    <template #item-actions="{ item }">
      <div class="tw-flex tw-gap-x-2">
        <a
          :href="item.url"
          title="Eigenaarsrapport downloaden"
          target="_blank"
          class="action tw-bg-info"
        >
          <i class="far fa-download" />
        </a>
        <button
          title="Eigenaarsrapport verwijderen"
          class="action tw-bg-danger"
          @click="deleteOwnerReport(item.id)"
        >
          <i class="far fa-trash" />
        </button>
      </div>
    </template>
  </DataTable>
</template>

<script>
import { errorModal } from '@/modalMessages'
import { poll, USER_LANG_OPTIONS } from '@/utils/helpers'

import DataTable from '@/components/DataTable'

import { getProjectOwners } from '@/services/projects'
import { getPropertyOwners } from '@/services/properties'
import {
  getPropertyOwnerReportsNew,
  getProjectOwnerReportsNew,
  deletePropertyOwnerReport,
  deleteProjectOwnerReport,
  exportPropertyOwnerReportCandidates,
  exportProjectOwnerReportCandidates,
  getCandidatesExportStatus
} from '@/services/ownerReports'

export default {
  name: 'OwnerReports',
  components: {
    DataTable
  },
  constants: {
    USER_LANG_OPTIONS
  },
  props: {
    project: {
      type: Object,
      default: null
    },
    property: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      exporting: false,
      exportLanguage: 'nl',
      loading: false,
      // The object ownerReports includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      ownerReports: {
        count: null,
        next: null,
        previous: null,
        results: []
      }
    }
  },
  computed: {
    headers () {
      return [
        { value: 'owner', text: 'Eigenaar' },
        { value: 'created_on', text: 'Aangemaakt op', orderable: true },
        { value: 'type', text: 'Type' },
        { value: 'status', text: 'Status' },
        { value: 'actions', text: 'Acties' }
      ]
    },
    ownerReportFormUrl () {
      if (this.property) {
        return {
          name: 'PropertyOwnerReportForm',
          params: { id: this.property.id }
        }
      }
      if (this.project) {
        return {
          name: 'ProjectOwnerReportForm',
          params: { id: this.project.id }
        }
      }
      return null
    }
  },
  created () {
    this.loadOwnerReports()
    this.loadOwnerLanguage()
  },
  methods: {
    openOwnerReportForm () {
      this.$router.push(this.ownerReportFormUrl)
    },
    async loadOwnerLanguage () {
      const response = this.property
        ? await getPropertyOwners(this.property.id)
        : await getProjectOwners(this.project.id)
      this.exportLanguage = response.data?.[0]?.language || 'nl'
      return response
    },
    async getOwnerReports (payload) {
      const response = this.property
        ? await getPropertyOwnerReportsNew(payload)
        : await getProjectOwnerReportsNew(payload)
      return response.data
    },
    async loadOwnerReports (data) {
      try {
        this.loading = true
        const payload = { ...data }
        this.property
          ? payload.propertyId = this.property.id
          : payload.projectId = this.project.id

        const ownerReports = await this.getOwnerReports(payload)
        this.ownerReports = ownerReports
        return ownerReports
      } catch (error) {
        errorModal('Fout bij het laden van eigenaarsrapporten, probeer het opnieuw.')
      } finally {
        this.loading = false
      }
    },
    async infiniteLoad ($infinite, next) {
      try {
        if (!next) {
          // No more data and state is loaded
          if (this.ownerReports.results.length) $infinite.loaded()
          return $infinite.complete()
        }
        const ownerReports = await this.getOwnerReports({ url: next })
        const results = [...this.ownerReports.results, ...ownerReports.results]
        this.ownerReports = { ...ownerReports, results }
        $infinite.loaded()
        return ownerReports
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },
    async deleteOwnerReport (ownerReportId) {
      try {
        const response = this.property
          ? await deletePropertyOwnerReport(this.property.id, ownerReportId)
          : await deleteProjectOwnerReport(this.project.id, ownerReportId)
        const objIndex = this.ownerReports.results.findIndex(obj => obj.id === ownerReportId)
        this.ownerReports.results.splice(objIndex, 1)
        if (this.ownerReports.count >= 1) this.ownerReports.count = this.ownerReports.count - 1
        return response
      } catch (error) {
        console.error(error)
        errorModal('Fout bij het verwijderen van het eigenaarsrapport, probeer het opnieuw.')
      }
    },
    async exportCandidates () {
      try {
        this.exporting = true
        const language = this.exportLanguage
        const response = await this.property
          ? await exportPropertyOwnerReportCandidates(this.property.id, { language })
          : await exportProjectOwnerReportCandidates(this.project.id, { language })
        const pollResult = await poll(response.data?.job_id, getCandidatesExportStatus, 1000)
        if (pollResult.url) {
          const win = window.open(pollResult.url, '_blank')
          if (!win) errorModal('Sta pop-ups toe om het bestand te downloaden.')
        }
        return pollResult.url
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het exporteren van de kandidaten.')
      } finally {
        this.exporting = false
      }
    }
  }
}
</script>
