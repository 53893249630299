var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataTable',_vm._b({staticClass:"tw--mx-5 tw-shadow-none",attrs:{"loading":_vm.loading,"headers":_vm.headers,"infinite-scroll":true},on:{"filter":_vm.loadOwnerReports,"infiniteLoad":_vm.infiniteLoad,"changedOrder":_vm.loadOwnerReports},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('div',{staticClass:"tw-mb-4 tw-flex tw-justify-between tw-gap-4 tw-flex-wrap"},[_c('router-link',{staticClass:"action tw-bg-success",attrs:{"to":_vm.ownerReportFormUrl}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Eigenaarsrapport ")]),_c('div',{staticClass:"tw-flex tw-flex-wrap tw-gap-4 tw-justify-end tw-items-center"},[_c('FormulateInput',{attrs:{"type":"select","name":"language","placeholder":"Selecteer taal","options":_vm.USER_LANG_OPTIONS,"input-class":['tw-text-sm tw-h-7 tw-my-0'],"outer-class":"tw-my-0"},model:{value:(_vm.exportLanguage),callback:function ($$v) {_vm.exportLanguage=$$v},expression:"exportLanguage"}}),_c('button',{staticClass:"action tw-bg-success tw-mr-0",attrs:{"type":"button","disabled":_vm.exporting},on:{"click":_vm.exportCandidates}},[_c('i',{class:[
              'fas tw-mr-1',
              _vm.exporting
                ? 'fa-spinner-third fa-spin'
                : 'fa-download'
            ]}),_vm._v(" "+_vm._s(_vm.exporting ? 'Exporteren ...' : 'CSV-export kandidatencontact')+" ")])],1)],1)]},proxy:true},{key:"item-owner",fn:function(ref){
            var item = ref.item;
return [(item.owner)?_c('router-link',{attrs:{"to":{ name: 'ContactDetails', params: { id: item.owner.id } }}},[_vm._v(" "+_vm._s(item.owner.display_name)+" ")]):_c('span',[_vm._v("-")])]}},{key:"item-created_on",fn:function(ref){
            var item = ref.item;
return [(item.created_on)?_c('span',[_vm._v(_vm._s(_vm._f("datetime")(item.created_on)))]):_c('span',[_vm._v("-")])]}},{key:"item-type",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"tw-flex tw-gap-x-2"},[_c('span',[_vm._v(" "+_vm._s(item.type === 'period' ? 'Tussentijds rapport' : 'Eindrapport')+" ")])])]}},{key:"item-status",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"tw-flex tw-gap-x-2"},[_c('span',{staticClass:"tw-bg-success tw-text-white tw-p-1 tw-text-xs tw-font-bold tw-rounded-md"},[_vm._v(" "+_vm._s(item.delivery_type === 'email' ? 'Verzonden via e-mail' : 'Bezorgd via post of in persoon')+" ")])])]}},{key:"item-actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"tw-flex tw-gap-x-2"},[_c('a',{staticClass:"action tw-bg-info",attrs:{"href":item.url,"title":"Eigenaarsrapport downloaden","target":"_blank"}},[_c('i',{staticClass:"far fa-download"})]),_c('button',{staticClass:"action tw-bg-danger",attrs:{"title":"Eigenaarsrapport verwijderen"},on:{"click":function($event){return _vm.deleteOwnerReport(item.id)}}},[_c('i',{staticClass:"far fa-trash"})])])]}}])},'DataTable',_vm.ownerReports,false))}
var staticRenderFns = []

export { render, staticRenderFns }